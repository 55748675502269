import './Info.scss';

function Info() {
  return (
    <section id="info" className="margin">
      <h2 className="info-heading">NASZA OFERTA</h2>
      <div className="info-flex">
        <div>
          <h3 className="small-heading">ZAKRES PRAC</h3>
          <ul>
            <li className="li-size">Ogrzewanie</li>
          </ul>
          <ul>
            <li className="li-size">Wentylacja</li>
          </ul>
          <ul>
            <li className="li-size">Klimatyzacja</li>
          </ul>
          <ul>
            <li className="li-size">Elektroenergetyka</li>
          </ul>
          <ul>
            <li className="li-size">Elektryka</li>
          </ul>
        </div>
        <div>
          <h3 className="small-heading">UPRAWNIENIA</h3>
          <ul>
            <li className="li-size">Elektryczne</li>
          </ul>
          <ul>
            <li className="li-size">Ciepłownicze</li>
          </ul>
          <ul>
            <li className="li-size">Gazowe</li>
          </ul>
          <ul>
            <li className="li-size">F-Gazy</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Info;
