import React, { useRef } from 'react';
import './Contact.scss';
import emailjs from '@emailjs/browser';
import phone from '../png/phone.png';
import mark from '../png/mark.png';

function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_kguy3uj', 'template_x2wig7j', form.current, 'user_q5yqaKLhx50An7EY0QU2X')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    document.getElementById('someForm').reset();
    alert('You have sent email.');
  };
  return (
    <section className="section-padding margin" id="contact">
      <h1 className="heading">KONTAKT</h1>
      <div className="contact">
        <div className="flexBox half-width">
          <form id="someForm" className="width" ref={form} onSubmit={sendEmail}>
            <div className="flexBox">
              <label>IMIĘ I NAZWISKO (WYMAGANE)</label>
              <input type="text" required name="user_name" />
              <label>ADRES EMAIL (WYMAGANE)</label>
              <input type="email" required name="from_name" />
              <label>TEMAT</label>
              <input type="text" name="user_subject" />
              <label>WIADOMOŚĆ</label>
              <textarea name="message" rows={10} />
              <input type="submit" value="WYŚLIJ" />
            </div>
          </form>
        </div>
        <div className="map">
          <div className="map-width">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d589.8923593914059!2d20.56123728670181!3d53.743742851569706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d53.7437611!2d20.5608698!5e0!3m2!1spl!2spl!4v1652206834686!5m2!1spl!2spl"
              className="iframe"
            />
            <div className="contact-box">
              <h3 className="heading-bottom">MWENERGY Michał Wolański</h3>
              <p>
                <a className="contact-text" href="tel:+48509244045">
                  <img src={phone} />
                  <p className="number">+ 48 509 244 045</p>
                </a>
              </p>
              <p>
                <div className="contact-text">
                  <img src={mark} />
                  <p className="number">10-687 Szczęsne ul. Widokowa 7</p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
