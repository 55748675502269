import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Slider.scss';
import img1 from '../jpg/jpg1.jpg';
import img2 from '../jpg/jpg2.jpg';
import img3 from '../jpg/jpg3.jpg';
import img4 from '../jpg/jpg4.jpg';

const slideImages = [
  {
    url: img1,
    caption: ''
  },
  {
    url: img2,
    caption: ''
  },
  {
    url: img3,
    caption: ''
  },
  {
    url: img4,
    caption: ''
  }
];

const Slideshow = () => {
  return (
    <div id="slider" className="slide-container margin">
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div className="each-slide" key={index}>
            <div style={{ backgroundImage: `url(${slideImage.url})` }}>
              <span>
                <h1>{slideImage.caption}</h1>
              </span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
